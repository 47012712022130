@import './fonts.css';
body {
  margin: 0;
  font-family: 'Cairo', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}

code {
  font-family: 'Cairo', sans-serif;
}

*{
  font-family: 'Cairo', sans-serif !important;
  box-sizing: border-box;
}

.fa, .fas, .far, .fab {
  font-family: 'Font Awesome 5 Free' !important; /* Ensure Font Awesome font is applied */
}

html, body, #root {
  height: 100%;
}

.btn-primary{
  /* background-color: #596FB7 !important; */
  background-color: #102C57 !important;
  border-color: #102C57 !important;
  color: #FFF9F7;
}

.btn-warning{
  background-color: #FFD35A !important;
  border-color: #FFD35A !important;
}

.btn-danger{
  
  background-color: #EB455F !important;
  border-color: #EB455F !important;
}

.w-200px{
  width: 200px !important;
}
/* #root{
  display: flex;
} */

.contntArea{
  background-color: #FbFbFb;
}

.sidebar-h{
  height: 100vh !important;
}

.head-div{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.head-div .head-text{
  font-weight: normal;
  margin-left: 20px;
}

.sidebar-content{
  height: calc(100% - 40px) !important;
}

.layer{
  height: calc(100% - 64px);
  overflow-y: auto;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #EBE0D3;
  border: 6px solid #FFF9F7;
}

*::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #75746C;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #A39D9C;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #A0A39F;
}

.page-link{
  margin-right: 5px !important;
  color:#102C57 !important ;
  border-radius: .375rem !important;
  padding: 3px 10px !important;
}

.active>.page-link{
  background: #102C57 !important;
    border: 1px solid #102C57 !important;
    color: #fff !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.saerch-input::placeholder{
  font-size: .9rem 
}

.form-container{
  /* margin:25px auto; */
	display:grid;
	width:100%;
	grid-template-columns:repeat(2, minmax(300px, 1fr));
	grid-gap:25px;
	grid-template-rows:1fr;
}
.img-placeholder{
background-color: #f9f9f9;
background-image: url(../public/picture_2659360.png);
background-repeat: no-repeat;
background-position: center;
border: .5px solid #eee;
background-size: 100px;

}
.video-placeholder{
  background-color: #f9f9f9;
background-image: url(../public/video-player_3601643.png);
background-repeat: no-repeat;
background-position: center;
background-size: 100px;
border: .5px solid #eee;
height: 260px;
max-height: 260px;
}

.nav-head{
  background-color: #F0F0F0 !important;
}

.progress{
  height: .5rem !important;
}

.progress-bar{
  background-color: #102C57 !important;
}

.text-sm{
  font-size: .9rem !important;
}

.qs-trash{
  z-index: 99;
  left: -15px;
  top: -15px !important;
 
}

.btn-trash{
  width: 25px ;
  height: 25px;
  padding: 0 !important;
  text-align: center;
  vertical-align: middle;
}

.btn-outline-primary{
  color: #102C57 !important;
}
.btn-outline-primary:hover{
background-color: transparent !important;
color: #102C57 !important;
}
.text-xs{
  font-size: x-small;
}
