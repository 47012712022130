/* src/fonts.css */
@font-face {
    font-family: 'Cairo';
    src: url('./fonts/cairo/Cairo-Regular.ttf') format('truetype'),
         url('./fonts/cairo/Cairo-Bold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Cairo';
    src: url('./fonts/cairo/Cairo-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  